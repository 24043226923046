import each from 'lodash/each'
import debounce from 'lodash/debounce'

import StringStyleSheet from './string-style-sheet'
import LiveStyleSheet from 'editor/helpers/style-loader/live-style-sheet'

let currentZoomFactor = null
let tempSwapStyleSheets
let stylesheets = {}
let storeZoom = null
let lastWindowWidth = 0

// export const updateMediaQueriesWidthsDebounce = debounce(function (deltaWidth, zoomFactor) {
//   each(stylesheets, sheet => {
//     sheet.updateMediaQueriesWidths(deltaWidth, zoomFactor)
//   })
// }, 200)

export const updateInternal = (zoom, includeTemp) => {
  // if (zoom.zoomFactor === window._zoomFactor) {
  //   return // No need to update any further..
  // }
  currentZoomFactor = zoom

  each(stylesheets, sheet => {
    sheet.updateMediaQueriesWidths(zoom)
  })
  if (includeTemp) {
    each(tempSwapStyleSheets, sheet => {
      sheet.updateMediaQueriesWidths(zoom)
    })
  }

  // some window code to commiunicate with navigation feature
  window._zoomFactor = zoom.zoomFactor
  if (window._zoomUpdateEvents) {
    window._zoomUpdateEvents.forEach(func => func())
  }
}
const updateMediaQueriesWidthsDebounce = debounce(updateInternal, 200)

export const getCssClass = layout => {
  return layout.id
}

class StyleLoader {
  static setCollectStyleSheetsForPublish (value) {
    // keep references of old stylesheets for publishing from the editor
    if (value) {
      tempSwapStyleSheets = stylesheets
      stylesheets = {}
    } else {
      stylesheets = tempSwapStyleSheets
      tempSwapStyleSheets = null
    }
    this.collectStyleSheetsForPublish = value
  }

  static getAllStyleSheetsForPublish () {
    let result = ''
    const imports = []
    each(stylesheets, sheet => {
      result += sheet.getCss() + '\n'
    })
    each(stylesheets, sheet => {
      sheet.getImports().forEach(imp => {
        if (!imports.includes(imp)) {
          imports.push(imp)
        }
      })
    })
    return imports.join('\n') + result
  }

  static clearAllStyleSheetsForPublish () {
    tempSwapStyleSheets = stylesheets
    stylesheets = {}
  }

  static updateMediaQueriesWidths (currentWindowWidth, editorWidth, zoomFactor) {
    if (zoomFactor === currentZoomFactor) {
      return
    }
    lastWindowWidth = currentWindowWidth
    const deltaWidth = currentWindowWidth - editorWidth
    // const deltaZoomFactor = currentZoomFactor / zoomFactor // TODO: @dvandervlag This was in mail, check if needed..

    // Update the current zoom factor.
    currentZoomFactor = zoomFactor

    // updateMediaQueriesWidthsDebounce(deltaWidth, zoomFactor)
    updateMediaQueriesWidthsDebounce({ deltaWidth, zoomFactor }) // TODO: @dvandervlag This was object in site, check if difference now..
  }

  static tempOverrideZoomFactor (editorWidth, zoomFactor) {
    updateMediaQueriesWidthsDebounce.flush() // invoke old stalled function
    storeZoom = currentZoomFactor || { zoomFactor: 1, deltaWidth: 0 }

    const currentWindowWidth = (lastWindowWidth === 0 ? window.innerWidth : lastWindowWidth)
    const deltaWidth = currentWindowWidth - editorWidth

    updateInternal({ deltaWidth, zoomFactor }, true)
  }

  static resetOverrideMediaQueriesScaling (callback) {
    if (storeZoom) {
      updateInternal(storeZoom)
      if (callback) {
        callback(storeZoom)
      }
    }
    storeZoom = null
  }

  static setCss (stylesheetKey, css, isBaseStyling, layout, colorModel, stylesheetCollector) {
    stylesheetCollector = stylesheetCollector || stylesheets
    const Class = this.collectStyleSheetsForPublish ? StringStyleSheet : LiveStyleSheet
    stylesheetCollector[stylesheetKey] = new Class(stylesheetKey, css, isBaseStyling, layout, colorModel, currentZoomFactor)
  }

  static removeCss (stylesheetKey) {
    if (stylesheets[stylesheetKey]) {
      stylesheets[stylesheetKey].dispose()
      delete stylesheets[stylesheetKey]
    }
  }

  static loadOrUpdateLayout (layout, colorModel) {
    const stylesheetKey = layout.id
    const stylesheetCollector = stylesheets[stylesheetKey] ? stylesheets : colorModel.stylesheetCollector || stylesheets

    if (!stylesheetCollector[stylesheetKey]) {
      this.setCss(stylesheetKey, layout.css, layout.id === 'base', layout, colorModel, stylesheetCollector)
    } else {
      stylesheetCollector[stylesheetKey].parseColors(colorModel)
    }
  }
}
export default StyleLoader
