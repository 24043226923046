import LogError from 'core/editor/helpers/log-error'

class LayoutHelper {
  static getFileNameFromPathOrParam (layoutsSettings, propName, fileName) {
    if (!fileName && layoutsSettings && layoutsSettings[propName].path) {
      const pathSplit = layoutsSettings[propName].path.split('/')
      if (pathSplit.length === 3) {
        fileName = pathSplit[2]
      }
    }
    return fileName
  }

  static getLayoutObj (layoutsSettings, propName, fileName) {
    fileName = this.getFileNameFromPathOrParam(layoutsSettings, propName, fileName)
    return this.internalGetLayoutObj(layoutsSettings[propName], fileName, propName)
  }

  static internalGetLayoutObj (layoutsObj) {
    if (!layoutsObj) {
      return null
    }
  }

  static hasLayoutFile (layoutsSettings, propName, fileName, Layouts) {
    const layoutObjObject = Layouts.getLayoutObject()
    if (!layoutObjObject) {
      return false
    }
    const layoutsObj = layoutsSettings[propName]
    const layout = layoutsObj.id ? layoutObjObject.getById(layoutsObj.id) : layoutObjObject.get(layoutsObj.path)
    return fileName ? layout.html_templates && layout.html_templates[fileName] : layout.html
  }

  static getLayout (layoutsSettings, propName, fileName) {
    fileName = this.getFileNameFromPathOrParam(layoutsSettings, propName, fileName)

    const layout = this.internalGetLayoutObj(layoutsSettings[propName], fileName, propName)

    if (layout) {
      if (fileName && fileName !== 'index' && !layout.compiledLayouts[fileName]) {
        fileName = 'index'
      }

      const result = layout.compiledLayouts[fileName || 'index']
      if (!result) {
        LogError.log(`layout not found: ${propName}/${fileName || 'index'}`)
        LogError.log(`Try to add this to the settings.json in your feature:\n"publishLayoutsWithRuntimeSite": ["${propName}"]`)
      }
      return result
    }
  }
}

export default LayoutHelper
