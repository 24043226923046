import each from 'lodash/each'

import { SECTION_STATUS as STATUS } from 'core/helpers/global/enums'

class BaseController {
  constructor (parentController, model, editorContext) {
    this.imports = parentController && parentController.imports
    this.editorContext = (parentController && parentController.editorContext) || editorContext
    this.parentController = parentController
    this.model = model

    const initialStatus = this.editorContext ? STATUS.EDITABLE : STATUS.READONLY
    this.status = (parentController && parentController.status) || initialStatus
    this.subControls = {}
  }

  setReadonly (value, recursive = true) {
    // No need to set the status when it's already non-readable
    if (value && this.status > STATUS.EDITABLE) {
      return
    }
    this.setStatus(value ? STATUS.READONLY : STATUS.EDITABLE, recursive)
  }

  setStatus (value, recursive = true) {
    if (this.status !== value) {
      if (!value && !this.editorContext) {
        throw new Error('no editor context')
      } else {
        this.status = value

        if (recursive) {
          // propegate to all children
          const children = this.getChildControls()
          if (children) {
            each(children, child => {
              child.controller.setStatus(value)
            })
          }

          if (this.subControls) {
            each(this.subControls, subControl => {
              subControl.controller.setStatus(value)
            })
          }
        }
      }
    }
  }

  registerView (view, model, addOrDeleteBool) {
    if (this.editorContext && model) {
      this.editorContext.registerView(view, model, addOrDeleteBool)
    }
  }

  editable () {
    return this.status === STATUS.EDITABLE
  }

  isThumbnail () {
    return this.status === STATUS.SECTION_PREVIEW
  }

  isRuntime () {
    return !this.editorContext
  }

  isPublishedSite () {
    if (window) {
      return Boolean(window._isPublished)
    } else {
      return false
    }
  }

  hasAnimations () {
    const theme = this.getSiteController().getTheme()
    return theme.animations ? theme.animations.enabled : false
  }

  // look recursive through parents to find right controller
  getControllerOfType (type) {
    let current = this
    while (current && current.constructor !== type) {
      current = current.parentController
    }
    return current
  }

  // look recursive through parents and find the site controller
  getSiteController () {
    let current = this
    while (current.parentController) {
      current = current.parentController
    }
    return current
  }

  getSectionController () {
    return this.getControllerOfType(this.imports.SectionController)
  }

  getContentController () {
    return this.getControllerOfType(this.imports.ContentController)
  }

  getPageController () {
    return this.getControllerOfType(this.imports.PageController)
  }

  // override able
  getChildControls () {}

  hasSubController (type) {
    return !!this.subControls[type]
  }

  getSubController (type) {
    return this.getControlOfType(type).controller
  }

  getControlOfType = type => {
    let control = this.subControls[type]
    if (control) {
      return control
    }

    // hacky way of getting model cause the path is not defined in the handlebars, only the control name
    let model = this.model[type] || this.model
    if (type === 'background') {
      model = this.model[type] || this.model.binding[type]
    } else if (type === 'content') {
      model = this.model.binding
    }

    const firstUpper = `${type[0].toUpperCase()}${type.slice(1)}`
    const controllerName = `${firstUpper}Controller`
    let _contructor = this.imports[controllerName]
    if (!_contructor) {
      _contructor = this.imports[`${firstUpper}Control`]
      if (!_contructor) {
        throw new Error(`can't find controller/control: ${firstUpper}[Controller|Control]`)
      } else {
        // construct base controller with a control
        const controller = new BaseController(this, model)
        controller.control = new _contructor(controller, model)
        this.subControls[type] = controller.control
        return controller.control
      }
    }
    const controller = new _contructor(this, model)
    control = controller.control

    this.subControls[type] = control
    return control
  }

  getValue = model => {
    // for page-header-control in page overview. just return the model
    return { ...model }
  }

  updateViewForGlobalBinding (globalBinding, paths) {
    // TODO
  }

  updateView (view, model, paths) {
    if (view.domElement) {
      const method = model.constructor === Array ? view.updateChildren : view.render
      method.call(view)
    }
  }

  dispose () {}
}
export default BaseController
