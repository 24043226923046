export const DEFAULT_GLOBAL_BINDING = {
  openingHours: {
    mon: [
      {
        description: 'Closed'
      }
    ],
    tue: [
      {
        description: 'Closed'
      }
    ],
    wed: [
      {
        description: 'Closed'
      }
    ],
    thu: [
      {
        description: 'Closed'
      }
    ],
    fri: [
      {
        description: 'Closed'
      }
    ],
    sat: [
      {
        description: 'Closed'
      }
    ],
    sun: [
      {
        description: 'Closed'
      }
    ]
  },
  address: {
    country: 'United States',
    state: '',
    city: 'Burlington',
    street: '10 Corporate Drive',
    zip: 'MA 01803'
  },
  phone: '0123456789',
  accounts: {
    facebook: 'facebook',
    twitter: 'twitter',
    instagram: 'instagram'
  }
}

export default DEFAULT_GLOBAL_BINDING
