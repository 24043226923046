var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "kv-video";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return " background-color: "
    + container.escapeExpression(((helper = (helper = helpers.color || (depth0 != null ? depth0.color : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"color","hash":{},"data":data,"loc":{"start":{"line":1,"column":95},"end":{"line":1,"column":104}}}) : helper)))
    + ";";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <video autoplay=\"true\" loop=\"true\" muted=\"true\" style=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasOpacity : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":110}}})) != null ? stack1 : "")
    + "\">\n      <source data-src=\""
    + container.escapeExpression(((helper = (helper = helpers.video || (depth0 != null ? depth0.video : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"video","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":33}}}) : helper)))
    + "\" type=\"video/mp4\" />\n    </video>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "opacity: "
    + container.escapeExpression(((helper = (helper = helpers.imageOpacity || (depth0 != null ? depth0.imageOpacity : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imageOpacity","hash":{},"data":data,"loc":{"start":{"line":3,"column":86},"end":{"line":3,"column":102}}}) : helper)))
    + ";";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.parallax : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":7,"column":60}}})) != null ? stack1 : "")
    + "\n  <div class='kv-background-inner "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pattern : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":97}}})) != null ? stack1 : "")
    + "' "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":99},"end":{"line":8,"column":141}}})) != null ? stack1 : "")
    + " style=\"\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0._disableLazyImages : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":79}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":30}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fixed : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.animate : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":98}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.color : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":52}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasOpacity : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":20,"column":55}}})) != null ? stack1 : "")
    + "\"></div>\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.parallax : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":21,"column":33}}})) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "<div class='kv-parallax-container'>";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "pattern-"
    + alias4(((helper = (helper = helpers.patternColor || (depth0 != null ? depth0.patternColor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"patternColor","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":73}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = helpers.patternIndex || (depth0 != null ? depth0.patternIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"patternIndex","hash":{},"data":data,"loc":{"start":{"line":8,"column":74},"end":{"line":8,"column":90}}}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "data-image=\""
    + container.escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data,"loc":{"start":{"line":8,"column":124},"end":{"line":8,"column":133}}}) : helper)))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0._disableLazyImages : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":72}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":65}}}) : helper)));
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.css || (depth0 != null ? depth0.css : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"css","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":22}}}) : helper)))
    + ";";
},"20":function(container,depth0,helpers,partials,data) {
    return "      background-attachment: fixed;\n      -webkit-background-size: cover;\n      -moz-background-size: cover;\n      -o-background-size: cover;\n      background-size: cover;\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "animation: 60s ease-in-out 0s infinite normal none running animateImage;";
},"24":function(container,depth0,helpers,partials,data) {
    var helper;

  return "background-color: "
    + container.escapeExpression(((helper = (helper = helpers.color || (depth0 != null ? depth0.color : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"color","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":44}}}) : helper)))
    + ";";
},"26":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class='kv-background "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.video : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":54}}})) != null ? stack1 : "")
    + "' style=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.color : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":112}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.video : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":22,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});