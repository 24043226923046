import each from 'lodash/each'
import get from 'lodash/get'

import GlobalProperties from 'core/helpers/global/global-properties'

import CollectPublish from '../collect-publish'

import LayoutHelper from 'editor/helpers/layout-helper'
import { iterateAllSectionsForSite } from 'editor/helpers/site-model-helper'
import { getAllFeatures, getFeatureSettings } from 'editor/managers/features/feature-helpers'

class EditorAndPublisherFeatures {
//////////////////////////////////
//////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
///

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////
/////

////////////////////////////////////////////////////////////////////////////////////////
////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////
////////////////////////////////////////////
/////////
//////////////////////
///////
////////////
//////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////
/////////////////////////////////////////////////////////////////
////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
/////////////
///////////

///////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////
/////////
////////
//////////
//////////////////////////
/////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////
/////////
/////////////////////////

////////////////////////////////////////////////////////////////////////////
////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////
/////////////
////////////////////////////////////////
/////////////////////////////
/////////////////
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////
////////////////////////////////////////////////////////
/////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////
/////////////
///////////
//////////
////////////////////
///////
/////
///

/////////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////////////////////
////////////////////////////////////////
///////
//////
///

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
///////////////////////////////
///

//////////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////
////////
/////
///

//////////////////////////////////////////////////////////
///////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////
////////
/////

///////////////////////////////////////////////
////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
///////
//////////////////////////////////////////////////////////////////////////
/////
///

//////////////////////////////////////////
//////////////////////////////
///
///////////
}
export default EditorAndPublisherFeatures
