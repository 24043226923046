import fetch from 'core/helpers/data/fetch-wrapper'
////////////////
/////////////////////////////////////
/////////

const makeScriptNode = scriptLink => {
  const script = document.createElement('script')
  script.src = scriptLink
  script.async = true
  script.defer = true
  document.head.append(script)
}

const loadedScripts = []
let appmarketPromise
let apps
const createdApps = {}

class AppMarketIntegration {
  static async load (url) {
    appmarketPromise = fetch.fetchAsJson(url).then(data => {
      apps = data
      return data
    })
  }

  static addScript (sku) {
    if (!appmarketPromise) {
      throw new Error('appmarket call not started')
    }
    AppMarketIntegration.getAppmarketData(sku).then(app => {
      if (app && app.url) {
        if (loadedScripts.indexOf(sku) < 0) {
          loadedScripts.push(sku)
          makeScriptNode(app.url)
        }
      } else {
        console.error("could n't add script for app " + sku)
      }
    })
  }

  static setAppmarketResult (data) {
    apps = data
    appmarketPromise = Promise.resolve(data)
  }

  static getAppSync (sku) {
    return (apps || []).find(i => i.sku === sku)
  }

  static hasApp (sku) {
    return !!(apps || []).find(i => i.sku === sku)
  }

  static wasAppCreated (sku) {
    return !!createdApps[sku]
  }

  // get or create the store app and return the info
  static ensureApp (sku, apiActions) {
    return new Promise((resolve, reject) => {
      if (!appmarketPromise) {
        apiActions.doAppmarketCall()
      }
      AppMarketIntegration.getAppmarketData(sku).then(app => {
        if (!app) {
          createdApps[sku] = true
          apiActions.createApp(sku, data => {
            if (data) {
              AppMarketIntegration.setAppmarketResult(data)
              AppMarketIntegration.getAppmarketData(sku).then(app2 => {
                resolve(app2)
              })
            } else {
              reject(new Error('No appmarket app returned'))
            }
          })
        } else {
          resolve(app)
        }
      })
    })
  }

  static getAppmarketData (sku) {
    if (!appmarketPromise) {
      return Promise.resolve()
    }

    return new Promise(resolve => {
      appmarketPromise.then(data => {
        if (data) {
          const app = data.find(i => i.sku === sku)
          if (app) {
            resolve(app)
            return
          }
        }
        resolve()
      })
    })
  }

  static initInsights () {
    AppMarketIntegration.addScript('AM_INSIGHTS')
    window.__bi__ =
      window.__bi__ ||
      function () {
        ;(window.__bi__.q = window.__bi__.q || []).push(arguments)
      }
    window.__bi__.l = +new Date()
  }

  static trackPageView (name) {
    window.__bi__('pageView', name)
  }

  static async getAppsInformation (completedApps) {
    const baseUrl = completedApps[0].url.split('shopping-cart')[0].replace('shop', 'shop-api')

    let url = `${baseUrl}v1.0/store/info?`
    completedApps.slice(0, 5).forEach((app, index) => {
      url += (index > 0 ? '&' : '') + 'instanceIds=' + app.instance_id
    })

    return fetch.fetchAsJson(url)
  }

//////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////
/////

//////////////////////////////////////////////////////////////////////

//////////////////////////////////
//////////////////
/////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////
//////////////////
////////////////////////////////////////
/////////////////////
/////

///////////////////////////////////////////////
/////////////////////////////////////////////////////
//////
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////
///////////////
///
///////////
}

export default AppMarketIntegration
