import CorePageControl from 'core/editor/controls/page-control'

import $ from 'jquery'
import Layout from '../templates/page-control.tpl'
import { selectByScroll, findSectionInView } from 'core/editor/helpers/select-by-scroll-helper'

class PageControl extends CorePageControl {
  constructor (controller) {
    super(controller, Layout)
  }

  getViewPort () {
    const fixedHeader = this.fixedHeader || document.querySelector('.kv-fixed-header')
    return {
      scrollY: window.pageYOffset,
      topPadding: fixedHeader ? fixedHeader.offsetHeight : 0,
      windowHeight: window.innerHeight
    }
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    const siteController = this.controller.getSiteController()

    this.initialized = false
    this.fixedHeader = document.querySelector('.kv-fixed-header')

    if (siteController.hasSectionBasedNavigation()) {
      this.sections = Array.prototype.slice.call(element.querySelectorAll('section'))
      this.activeSection = findSectionInView(this.sections, this.getViewPort())
      window.addEventListener('scroll', this.onWindowScroll)
      window.addEventListener('resize', this.onWindowResize)
    }

    // Animations
    if (this.controller.isRuntime() && this.controller.hasAnimations() && window.IntersectionObserver) {
      const animationSettings = (this.animationSettings = siteController.getTheme().animations)

      this.observer = new window.IntersectionObserver(this.onElementIntersect, {
        // If element gets within 50px go get it
        rootMargin: '50px 0px',
        threshold: 0.01
      })

      this.initializeAnimationElements(element, animationSettings)
    } else {
      // Remove class .kv-notify-inview from all elements
      const nodeList = element.querySelectorAll('.kv-notify-inview')
      Array.prototype.forEach.call(nodeList, node => node.classList.remove('kv-notify-inview'))
    }

    if (this.controller.isRuntime()) {
      const modalElements = element.querySelectorAll('.open-in-modal')
      modalElements.forEach(el => {
        const sourceUrl = el.getAttribute('href')

        const options = this.getIframeOptions(sourceUrl)
        el.setAttribute('href', 'href="javascript:void(0)"')
        el.onclick = () => window.showModal(options)
      })

      this.checkQueryParamsForModal()
    }
  }

  getIframeOptions = (url) => {
    const content = `<iframe
    class="inline-website"
    frameborder="0"
    allowTransparency="true"
    allowfullscreen="true"
    scrolling="yes"
    allow="encrypted-media"
    src="${url}">
  </iframe>`

    const options = {
      content: content,
      fullscreenOnMobile: true
    }

    return options
  }

  checkQueryParamsForModal = () => {
    const params = document.location.search.replace('?', '').split('&')
    const parameters = params.map(p => ({ key: p.split('=')[0], value: p.split('=')[1] }))
    const modalParam = parameters.find(p => p.key === 'showModal')

    if (modalParam) {
      const model = this.controller.getSiteController().model
      let modalUrl = model.globalFeatureModel[modalParam.value]
      if (modalUrl) {
        const extraParams = parameters.filter(p => p.key !== 'showModal')

        const sep = modalUrl.includes('?') ? '&' : '?'
        modalUrl += sep
        extraParams.forEach((p, i) => {
          modalUrl += i > 0 ? '&' : ''
          modalUrl += p.key + '=' + p.value
        })
        const options = this.getIframeOptions(modalUrl)
        window.showModal(options)
      }
    }
  }

  dispose () {
    super.dispose()

    window.removeEventListener('scroll', this.onWindowScroll)
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowResize = () => {
    this.initialized = false
  }

  onWindowScroll = () => {
    selectByScroll({
      ...this.getViewPort(),
      sections: this.sections,
      activeSection: this.activeSection,
      onSelectSection: newActiveSection => {
        this.activeSection = newActiveSection
        const controls = this.controller.getChildControls()
        const activeControl = controls.find(i => i.domElement === newActiveSection)

        const sectionNavigations = document.querySelectorAll('.kv-site:not(.kv-edit-mode) *[data-uri-path]')
        const sectionNavigation =
          activeControl &&
          document.querySelector(
            '.kv-site:not(.kv-edit-mode) *[data-uri-path="section-' + activeControl.model.id + '"]'
          )

        for (let i = 0; i < sectionNavigations.length; i++) {
          sectionNavigations[i].classList.remove('active')
        }
        if (sectionNavigation) {
          sectionNavigation.classList.add('active')
        }
      }
    })
  }

  initializeAnimationElements = (pageElement, animationSettings) => {
    const elements = pageElement.querySelectorAll(
      '.kv-notify-inview, *[data-type="title"], *[data-type="button"], *[data-type="map"], *[data-type="image"], *[data-type="link"], *[data-type="text"], *[data-type="item"], *[data-type="social"], .embed-container, .list-inline, input, textarea, button, select'
    )

    elements.forEach(element => {
      const $element = $(element)

      if ($element.hasClass('kv-notify-inview')) {
        if (this.observer) {
          this.observer.observe(element)
        }
        return true
      }

      if (
        $element.parents('*[data-type]:not(*[data-type="list"], .simpleParallax, .swiper-container, .no-animation)')
          .length === 0
      ) {
        element.style.opacity = 0
        if (this.observer) {
          this.observer.observe(element)
        }
      }

      if ($element.parent('.simpleParallax').length > 0) {
        element.parentElement.style.opacity = 0
        if (this.observer) {
          this.observer.observe(element.parentElement)
        }
      }
    })
  }

  onElementIntersect = entries => {
    // Loop through the entries
    entries.forEach(entry => {
      // Are we in viewport?
      if (entry.intersectionRatio > 0) {
        // Stop watching and load the image
        const element = entry.target

        const distance = this.animationSettings.distance
        const duration = this.animationSettings.duration

        this.observer.unobserve(element)
        if (element.classList.contains('kv-notify-inview')) {
          element.classList.add('kv-inview')
        } else {
          this.controller.imports.Velocity(
            element,
            { translateY: [0, distance], opacity: [1, 0] },
            duration,
            'ease-in-out'
          )
        }
      }
    })
  }
}
export default PageControl
