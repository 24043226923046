import BaseController from './base-controller'

import cloneDeep from 'lodash/cloneDeep'
import defer from 'lodash/defer'
import each from 'lodash/each'
import find from 'lodash/find'

import CustomHeaderButton from 'core/editor/managers/custom-header-button-manager'

import { getFirstLocation } from 'core/helpers/managers/global-binding/location-helper'

import Dictionary from 'core/editor/helpers/dictionary'
import GlobalProperties from 'core/helpers/global/global-properties'

///////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////

import EventEmitter from 'core/helpers/data/event-emitter'

// TODO: @dvandervlag This should be renamed.. (And everything that says 'site' as well.)
class SiteController extends BaseController {
  constructor (imports, editorContext, model, baseStyle) {
    super(null, model, editorContext)
    this.imports = imports

    if (baseStyle) {
      baseStyle.id = 'base'
      this.baseStyle = baseStyle
    }

    this.animationElementsSelector = '*[data-type="title"], *[data-type="button"], *[data-type="text"], *[data-type="item"], .embed-container, .list-inline'
    this.bindEvents()

    this.controlDictionary = new Dictionary(page => {
      const pageController = new this.imports.PageController(this, page)
      return pageController.control
    })
    this.control = new imports.SiteControl(this)
    this.customHeaderButtons = new CustomHeaderButton(this.forEachPageElement)

    if (editorContext) {
      this.events = new EventEmitter()
      editorContext.setSiteController(this)
      Communication.on(this.onRouteInfoChange, 'route-change')

      // the long waiting select first section code!
      this.selectHandle = setTimeout(this.selectFirstSection, 1)
      this.pageStateCache = null
    }
  }

  forEachPageElement = callback => {
    this.controlDictionary.getValues().forEach(control => {
      if (control.domElement) {
        callback(control.domElement)
      }
    })
  }

  forEachContentControls (onlyLoaded, callback) {
    ;(onlyLoaded ? this.getLoadedPageControllers() : this.getAllPageControllers()).forEach(pageController =>
      pageController.forEachContentControls(callback)
    )
  }

  getLoadedPageControllers () {
    return this.controlDictionary.getValues().map(i => i.controller)
  }

  getAccountForRemoteSource (remoteSource) {
    if (!remoteSource) {
      return null
    }

    let type
    if (remoteSource.accountType) {
      type = remoteSource.accountType.toLowerCase()
    } else {
      type = remoteSource.type ? remoteSource.type.toLowerCase() : remoteSource
    }
    const globalBinding = this.model.globalBinding

    let globalBindingValue = globalBinding.accounts && globalBinding.accounts[type]
    if (typeof globalBindingValue === 'object') {
      globalBindingValue = globalBindingValue.value
    }
    return globalBindingValue || remoteSource.value
  }

  applyFont (isPublisher) {
    const fonts = this.getTheme().fonts
    if (this.imports.FontLoader && fonts) {
      this.imports.FontLoader.applyFont(fonts, isPublisher) // load css
    }
  }

  bindEvents () {
    // TODO: rewrite this.model.activeEditPage.listen(this.updateActivePageProp)
    if (typeof window !== 'undefined' && window && window.addEventListener) {
      window.addEventListener('scroll', this.determineAnimationClasses)
    }
  }

  selectFirstSection = () => {
    if (this.editorContext) {
      this.editorContext.selectSection(this.getActivePageControl().getChildControls()[0])
    }
  }

  dispose () {
    this.control.dispose()
    if (window) {
      window.removeEventListener('scroll', this.determineAnimationClasses)
    }

    if (this.editorContext) {
      clearTimeout(this.selectHandle)
      Communication.off(this.onRouteInfoChange)
    }
  }

  getActivePageControl () {
    return this.controlDictionary.getOrAdd(this.getActivePageModel())
  }

  getActivePageIndex () {
    return this.model.pages.indexOf(this.getActivePageModel())
  }

  getActivePageController () {
    return this.getActivePageControl().controller
  }

  getActivePageModel () {
    const uri = this.selectedPage || this.getMainPageUri()
    const page = find(this.model.pages, { uriPath: uri }) || this.getMainPage()

    if (!page) {
      throw new Error('no pages in site!')
    }
    return page
  }

  getAllSectionsForPage (pageData) {
    const sectionArray = pageData.sections || []
    const globalSections = this.model.globalSections
    if (globalSections) {
      return [pageData.headerSection || globalSections.header, ...sectionArray, globalSections.footer].filter(i => i)
    }
    return sectionArray
  }

  updateActivePageProp = () => {
    if (this.control.domElement) {
      this.control.updateChildren() // rerender
    }
  }

  getChildControls = () => {
    return [this.getActivePageControl()]
  }

  determineAnimationClasses = (animate = true) => {
    const top = window.pageYOffset
    const checkScrollElements = document.querySelectorAll('.kv-check-scroll')
    let topOffset = 10
    if (checkScrollElements.length > 0 && checkScrollElements[0].dataset.kvScrollOffset) {
      const kvScrollOffset = ~~checkScrollElements[0].dataset.kvScrollOffset
      if (kvScrollOffset > 0) {
        topOffset = kvScrollOffset
      }
    }

    if (top >= topOffset) {
      each(checkScrollElements, element => {
        element.classList.add('kv-scrolled')
      })
    } else {
      each(checkScrollElements, element => {
        element.classList.remove('kv-scrolled')
      })
    }
  }

////////////////////
//////////////////////
/////////////////////////////////////////////////////////
///

//////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////

///////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////

////////////////////////////////////////

/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

///////////////
///
///////////

  bindAllElements (el) {
    this.control.setChildrenRecursive()
    this.control.setElement(el, false)
  }

  render (element, loadData) {
    GlobalProperties.site = this.model

    this.applyFont()
    const el = this.control.render(loadData)
    if (element) {
      element.appendChild(el)
    }
    return el
  }

  getTheme () {
    return this.model.theme
  }

  getGlobalBinding (model) {
    const locationId = model && model.globalLocationId
    if (this.imports.GlobalBindingHelper) {
      if (!this.mergedBinding) {
        const siteBinding = cloneDeep(this.model.globalBinding)
        this.mergedBinding = this.imports.isPublisher ? siteBinding : this.imports.GlobalBindingHelper.mergeGlobalBindingWithDefaultValues(siteBinding)
        this.mergedBinding.theme = this.getTheme()
        // cache for 1 render cycle
        defer(() => (this.mergedBinding = null))
      }

      // copy locations fields just in time to real global binding
      const locations = this.mergedBinding.locations
      if (locations) {
        const location = locations[locationId] || getFirstLocation(locations)
        this.imports.GlobalBindingHelper.getLocationFields().forEach(field => {
          this.mergedBinding[field] = location[field]
        })
      }
      return this.mergedBinding
    } else {
      return this.model.globalBinding
    }
  }

  getDataFromDatasource (dataSource, locationId = null, getCollection = false) {
    let data = this.model.datasources[dataSource.type]
    const isObject = data && typeof data === 'object' && !Array.isArray(data)

    // When datasource is an object with multiple locations
    if (isObject) {
      const locations = this.getGlobalBinding(this.model).locations
      if (locationId !== null && locations) {
        const globalBinding = locations[locationId]
        const source = (globalBinding.singleplatformAccount || {}).value || globalBinding.singleplatformAccount
        data = data[source] || []
      } else {
        // Return first object when no locationId set
        data = data[Object.keys(data)[0]]
      }
      if (!getCollection) {
        const dataFromPath = dataSource.path && data.find(i => i.id === dataSource.path)
        if (!dataFromPath) {
          // Return the first from array when no section selected
          return data.length ? data[0] : []
        }
        return dataFromPath
      }
    }
    return data
  }

  getMainPageUri () {
    const mainPage = this.getMainPage()
    if (mainPage) {
      return mainPage.uriPath
    }
  }

  getMainPage () {
    return find(this.model.pages, { mainPage: true }) || this.model.pages[0]
  }

  internalGenerateMenu = (generateItemsCallback) => {
    const sectionNavigation = this.hasSectionBasedNavigation()

    let items
    if (sectionNavigation) {
      const sections = this.getAllSectionsForPage(this.getActivePageModel())

      items = sections
        .filter(i => !i.hideFromNavigation && (i.hideFromNavigation !== undefined || i.category !== 'footers'))
        .map((section, index) => ({
          title:
            section.navigationTitle ||
            GlobalProperties.localize(
              `designer.categories.${section.category === 'headers' ? 'firstSectionInNavigation' : section.category}`
            ),
          uriPath: 'section-' + section.id,
          index: index,
          active: index === 0
        }))
    } else {
      const activePage = this.getActivePageModel()
      const mainPageUri = this.getMainPageUri()

      if (generateItemsCallback && typeof generateItemsCallback === 'function') {
        items = generateItemsCallback(activePage, mainPageUri)
      }
    }
    items = items.filter(item => {
      return item.title !== undefined && item.title !== ''
    })
    return items
  }

  getNavigation () {
    if (!this.navigation) {
      this.navigation = this.generateMenu()
      // cache for 1 render cycle
      defer(() => (this.navigation = null))
    }
    return this.navigation
  }

//////////////////
///////////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////
///////////////////////////////
/////////////////////////
///////////////////////////////////////////////////////
////////////////
//////////////////////////////////////////////////////
/////////
///////
///////////////////////////////////////////////////
/////////////////////////////
///////
/////
///

/////////////////////
///////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////
/////////////////////////////
/////////
/////
////////////////////////////////////////////////
//////////////////////////////////////////////////
///

/////////////////////
//////////////////////////////
///

/////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////
///

////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
///

///////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///

///////////////////////////////
////////////////////////////////////////////////////
///
///////////

  getValue = model => {
    const data = {
      ...model,
      global: this.getGlobalBinding(model)
    }
    return data
  }
}
export default SiteController
