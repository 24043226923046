import each from 'lodash/each'
import 'editor/helpers/handlebars/helpers'

const makeDomElement = html => {
  const template = document.createElement('div')
  template.innerHTML = html
  return template.childNodes[0]
}

class SiteRenderer {
  preRender () {
    this.html = this.renderHtml()
  }

  render (parentBinding, index) {
    this.renderPending = false

    let html = this.html
    this.html = ''
    if (!html) {
      if (parentBinding) {
        html = this.renderHtml(parentBinding, index)
      } else {
        html = this.renderHtml(this._parentBinding, this._index)
      }
    }

    const newElement = makeDomElement(html)

    if (this.controller.editable() && newElement && typeof newElement.querySelectorAll === 'function') {
      // Disable links for editor
      each(newElement.querySelectorAll('a'), e => {
        if (e.closest('[data-type="text"]') || e.closest('[data-type="author-credits"]')) {
          if (e.dataset.href && !e.href) {
            e.href = e.dataset.href
          }

          if (typeof window !== 'undefined' && window.top !== window.self) {
            if (!e.href.startsWith(window.location.origin)) {
              e.target = '_blank'
            }
          }
          return true
        }

        if (e.href && e.href.indexOf('#') === -1) {
          e.dataset.href = e.href
          e.removeAttribute('href')
        }
        if (e.target) {
          e.dataset.target = e.target
          e.removeAttribute('target')
        }
      })
    }
    // this.postProcessHtml(newElement)
    this.setElement(newElement, true)
    return newElement
  }

  postProcessHtml (element) {
    console.warn('POST PROCESS', element)
  }
}
export default SiteRenderer
