import CoreSectionControl from 'core/editor/controls/section-control'

import Layout from './templates/section-control.tpl'

////////////////
/////////////////////////////////////
//////////////////////////////////////////////////////////

////////////////////
/////////

class SectionControl extends CoreSectionControl {
  constructor (controller, model) {
    super(controller, model, Layout)
  }
}
export default SectionControl
