import BaseController from './base-controller'

import defer from 'lodash/defer'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { PickColor } from 'core/editor/managers/color-manager'
import ColorFunctions from 'core/editor/helpers/color-functions'
import { pickImage, pickUrl } from 'editor/helpers/images/image-size-helper'

const CssIdPrefix = 'background-id_'

const setOpacity = (element, opacity) => {
  if (element) {
    element.style.opacity = opacity / 100
  }
}

class BackgroundController extends BaseController {
  constructor (parentController, model) {
    super(parentController, model || {})

    this.calculatedNeighbor = undefined
    this.control = new this.imports.BackgroundControl(this, this.model)
  }

  hasPreventAlternationColor () {
    const sectionController = this.getSectionController()
    return sectionController.getLayoutObj('section').metadata?.preventAlternatingColor
  }

  hasMedia () {
    const model = this.model
    return (model.opacity === undefined || model.opacity > 0) && (model.img || model.image || model.video)
  }

  hasNeighborWithTheSameColor (excludeOverlayColor) {
    if (this.calculatedNeighbor !== undefined) {
      return this.calculatedNeighbor
    }
    const background = this.model

    if (background.color && !excludeOverlayColor) {
      return false
    }

    const colorIndex = (background && background.colorIndex) || 0

    const sectionController = this.getSectionController()
    const pageController = sectionController.getPageController()
    const sections = pageController.getSectionControllers()
    const indexOfSection = sections.indexOf(sectionController)

    const colorPallet = pageController.getSiteController().getTheme().colors
    const color = PickColor(colorPallet, colorIndex)

    let result = false
    if (!this.hasPreventAlternationColor() && indexOfSection > 0 && !this.hasMedia()) {
      const previousBackground = sections[indexOfSection - 1].getSubController('background')
      const previousColor = previousBackground.getBackgroundColor()
      if (ColorFunctions.equals(color, previousColor) && !previousBackground.hasMedia()) {
        result = true
      }
    }

    // cache very short, the section calculates it's color based on the previous section, this code can get called quitte often with many sections (n * n / 2)
    if (!excludeOverlayColor) {
      this.calculatedNeighbor = result
      defer(() => {
        this.calculatedNeighbor = undefined
      })
    }
    return result
  }

  getBackgroundColor = excludeOverlayColor => {
    const background = this.model

    if (background.color && !excludeOverlayColor) {
      return background.color
    }

    const colorIndex = (background && background.colorIndex) || 0
    const colorPallet = this.getSiteController().getTheme().colors
    let color = PickColor(colorPallet, colorIndex)

    if (this.hasNeighborWithTheSameColor(excludeOverlayColor)) {
      color = ColorFunctions.makeAdjacentColor(color)
    }
    return color
  }

  // the unique id which we use as css selector to refer to which variants of base-styling colors we use.
  getCssId () {
    const model = this.model
    const opacity = !isNil(model.opacity) ? model.opacity : 100

    const media = this.hasMedia() ? 'm' + parseInt(Math.log10(opacity) * 10) : '' // do on log scale because low levels of opacity make the most difference visually, range 0-20
    const color = model.color ? model.color.join('') : model.colorIndex || 0
    return `${CssIdPrefix}${color}${media}${this.hasNeighborWithTheSameColor() && !this.hasPreventAlternationColor() ? 'adjacent' : ''}`
  }

  getValue = model => {
    const background = this.model
    const color = this.getBackgroundColor()
    const sectionController = this.getSectionController()
    const pageController = sectionController.getPageController()
    const sections = pageController.getSectionControllers()
    const indexOfSection = sections.indexOf(sectionController)

    // get background color for rendering
    const result = {
      ...background,
      color: ColorFunctions.toRgba(color)
    }

    if (background.patternIndex !== undefined) {
      result.pattern = true
      result.patternColor = ColorFunctions.colorLightness(...color) <= 0.5 ? 'white' : 'black'
    } else {
      result.pattern = false
    }

    // Dont lazy load first 2 sections for Google Pagespeed score.
    if (indexOfSection === 0 || indexOfSection === 1) {
      result._disableLazyImages = true
    }

    let imageUrl
    const obj = background.img || background.image
    const status = this.parentController.status

    if (typeof obj === 'object') {
      imageUrl = pickImage(obj, 'background', status)
    } else {
      imageUrl = pickImage({ value: background.image }, 'background', status)
    }
    result.image = imageUrl

    if (imageUrl || background.video || background.patternIndex) {
      result.imageOpacity = result.opacity / 100
      result.hasOpacity = result.opacity || result.opacity === 0
    }
    return result
  }

  updateView (view, model, paths, useRecallCss = true) {
    let recalcCss = false
    const sectionController = this.getSectionController()
    const keys = Object.keys(paths)
    if (keys.length === 1 && keys[0] === 'opacity') {
      const opacity = !isNil(model.opacity) ? model.opacity : 100
      if (this.control.domElement) {
        setOpacity(this.control.domElement.querySelector('.kv-background-inner'), opacity)
        setOpacity(this.control.domElement.querySelector('video'), opacity)
        recalcCss = true
      }
    } else if (keys.length && keys.every(i => i.indexOf('img.authorName') === 0)) {
      super.updateView(view, model, paths)
    } else if (keys.length && keys.every(i => i.indexOf('img.value') === 0)) {
      const src = pickUrl(model.img, 'background')
      if (this.control.domElement) {
        const backgroundInner = this.control.domElement.querySelector('.kv-background-inner')
        if (backgroundInner) {
          backgroundInner.style.backgroundImage = `url(${src})`
        }
      }
    } else if (keys.length === 1 && keys[0] === 'transform') {
      const transform = model.transform
      if (this.control.domElement) {
        const backgroundInner = this.control.domElement.querySelector('.kv-background-inner')
        if (backgroundInner) {
          backgroundInner.style.transform = transform
        }
      }
    } else {
      if (paths['theme.colors'] && model.patternIndex === undefined) {
        // update dom manually for instance color switching and animations
        const color = ColorFunctions.toRgba(this.getBackgroundColor())
        if (this.control.domElement) {
          const inner = this.control.domElement.querySelector('.kv-background-inner')
          if (inner) {
            inner.style.backgroundColor = color
          }
          this.control.domElement.style.backgroundColor = color
        }
      } else {
        super.updateView(view, model, paths)
      }
      recalcCss = true
    }

    if (sectionController && useRecallCss) {
      if (recalcCss) {
        const clM = sectionController.colorManager
        if (clM) {
          clM.changeBackground()
        }

        // update the section classlist to which background is used
        const classList = get(sectionController, 'control.domElement.classList')
        if (classList) {
          ;[...classList].forEach(item => {
            if (item.indexOf(CssIdPrefix) === 0) {
              classList.remove(item)
            }
          })
          classList.add(this.getCssId())
        }
      }
    }
  }
}
export default BackgroundController
