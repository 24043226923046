var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "z-index: 2;";
},"3":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../../../services/app-designer/helpers/control.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"background",{"name":"control","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":28}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"kv-prevent-edit-overlay\" data-type=\"prevent-edit-overlay\"><i class=\"material-icons\">settings</i></div>\n  <div class=\"kv-tooltip\"></div>\n  <ul class=\"kv-control kv-section-add-controls\">\n    <li class=\"kv-section-controls-add-section kv-editor-button\" data-property=\"ee--editor--add-section-button\"\n      data-element-label=\"ee--editor--add-section\"\n      data-element-type=\"button\"\n    >\n      <i class=\"material-icons\">add</i>\n      <span>Add Section</span>\n    </li>\n  </ul>\n  <ul class=\"kv-control kv-section-controls\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobileMode : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobileMode : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":54,"column":11}}})) != null ? stack1 : "")
    + "    <li class='kv-section-remove kv-editor-button'\n      data-property=\"ee--editor--delete-section-button\"\n      data-element-label=\"ee--editor--delete-section\"\n      data-element-type=\"button\"\n    ><i class=\"material-icons\">delete</i></li>\n  </ul>\n  <div class=\"kv-section-overlay\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "      <li class='kv-section-up kv-editor-button'\n        data-property=\"ee--editor--move-section-up-button\"\n        data-element-label=\"ee--editor--section-up\"\n        data-element-type=\"button\"\n      ><i class=\"material-icons\">keyboard_arrow_up</i></li>\n      <li class='kv-section-down kv-editor-button'\n        data-property=\"ee--editor--drag-section-button\"\n        data-element-label=\"ee--editor--section-down\"\n        data-element-type=\"button\"\n      ><i class=\"material-icons\">keyboard_arrow_down</i></li>\n      <li class='kv-section-carousel kv-editor-button'\n        data-property=\"ee--editor--view-sections-button\"\n        data-element-label=\"ee--editor--view-sections\"\n        data-element-type=\"button\"\n      ><i class=\"material-icons\">view_carousel</i></li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "      <li class='kv-section-settings kv-editor-button'\n        data-property=\"ee--editor--settings-button\"\n        data-element-label=\"ee--editor--settings\"\n        data-element-type=\"button\"\n      ><i class=\"material-icons\">settings</i></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<section class=\""
    + alias2(alias1((depth0 != null ? depth0.cssClass : depth0), depth0))
    + "\" style=\""
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.background : depth0)) != null ? stack1.fixed : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + " position:relative;\">\n  <a name=\"section"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\"></a>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.renderBackground : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"position-relative kv-content\">\n    "
    + alias2(__default(require("../../../../../services/app-designer/helpers/control.js")).call(alias3,"content",{"name":"control","hash":{},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":25}}}))
    + "\n  </div>\n"
    + ((stack1 = __default(require("../../../../../services/app-designer/helpers/editable.js")).call(alias3,{"name":"editable","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + "</section>\n";
},"useData":true});