import CoreStyleLoader from 'core/editor/helpers/style-loader'

function updateInternal (zoom) {
  // some window code to commiunicate with navigation feature
  window._zoomFactor = zoom.zoomFactor
  if (window._zoomUpdateEvents) {
    window._zoomUpdateEvents.forEach(func => func())
  }
}

class StyleLoader extends CoreStyleLoader {
  static tempOverrideZoomFactor (editorWidth, zoomFactor) {
    CoreStyleLoader.tempOverrideZoomFactor(editorWidth, zoomFactor)
    updateInternal(zoomFactor)
  }

  static resetOverrideMediaQueriesScaling () {
    CoreStyleLoader.resetOverrideMediaQueriesScaling((zoom) => {
      updateInternal(zoom)
    })
  }
}
export default StyleLoader
