var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../../../services/app-designer/helpers/control.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.key),(data && data.index),depth0,{"name":"control","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":32}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-12 text-center add-container kv-control\">\n      <button class=\"kv-add-item-btn kv-editor-button large\">"
    + container.escapeExpression(__default(require("../../../../../services/app-designer/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"_addItemLabel","span",depth0,false,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":21,"column":61},"end":{"line":21,"column":103}}}))
    + "</button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div>\n  <div class=\"row\" data-type=\"list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0._children : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"row\" data-type=\"swipe-list\">\n    <div class=\"swiper-container\" style=\"padding-bottom: 25px;\">\n      <div class=\"swiper-wrapper\">\n\n      </div>\n      <!-- Add Pagination -->\n      <div class=\"swiper-pagination\" style=\"bottom: 0;\"></div>\n    </div>\n  </div>\n"
    + ((stack1 = __default(require("../../../../../services/app-designer/helpers/editable.js")).call(alias1,{"name":"editable","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});